<template>
    <NuxtLink
        :to="'/'"
        @click.native="generalStore.deleteSubPage(); contentStore.deleteSubPageContent()"
    > 
        <h3>CD Guidelines</h3>
    </NuxtLink>

    <div class="login__button" @click="openLoginModal(true)">
        <span v-if="!loggedIn">Anmelden</span>
        <span v-if="loggedIn">Abmelden</span>
    </div>
</template>

<script setup>
    import { useGeneralStore } from "/stores/general"
    import { useMainContentStore } from '/stores/mainContent'

    const emit = defineEmits([
        'toggleLoginModal'
    ])

    const openLoginModal = (value) => {
        emit('toggleLoginModal', value)
    }

    const contentStore = useMainContentStore()
    const generalStore = useGeneralStore()
    const { loggedIn } = storeToRefs(generalStore)
</script>