<template>
    <div class="c-download-area">
        <SlideLeftTransition>
            <div class="c-download-trigger">
                <button
                    v-if="showDownloadArea"
                    class="download__close-button btn btn-secondary btn-circle-close"
                    @click="showDownloadArea = !showDownloadArea"
                ></button>

                <button
                    v-if="!showDownloadArea"
                    class="download-area-button btn btn-secondary btn-circle btn-circle--small"
                    @click="showDownloadArea = !showDownloadArea"
                >
                    <i class="icon-download"></i>
                </button>

                <div v-if="fileCount > 0 && !showDownloadArea" class="file-amount-container">
                    <span class="file-amount">{{ fileCount }}</span>
                </div>
            </div>
        </SlideLeftTransition>

        <DownloadAreaTransition>
            <div 
                v-if="showDownloadArea"
                class="c-download-container"
            >
                <div class="download__title">
                    <h3>Downloadliste</h3>
                </div>
    
                <div class="download__list">
                    <ul>
                        <li
                            v-for="file in filenames"
                            class="download__file"
                        >
                            <button
                            class="btn btn-secondary btn-circle-close"
                            @click="removeFile(file.fileID)"
                            ></button>
                            <span>{{ file.fileName }}</span>
                        </li>
                    </ul>
                </div>
    
                <div 
                    v-if="files.length > 0"
                    class="download__controls"
                >
                    <button
                        class="btn btn-secondary"
                        @click="downloadFiles()"
                    >
                        Herunterladen
                    </button>
    
                    <button 
                        class="btn btn-clear-list"
                        @click="removeAll()"
                    >
                        Liste leeren
                    </button>
                </div>
    
                <div
                    v-else
                    class="no-files-checked"
                >
                    <span>Es wurden keine Dateien zum Download ausgewählt.</span>
                </div>
            </div>
        </DownloadAreaTransition>
    </div>
</template>

<script setup>
    import { useDownloadStore } from '/stores/download'
    import localforage from 'localforage/dist/localforage.js'

    const downloadStore = useDownloadStore()
    const { files, filenames, fileCount } = storeToRefs(downloadStore)

    const localForageList = await localforage.getItem('downloadList')
    const downloadList = ref([])
    let downloadArray = localForageList !== null ?  localForageList.split(',') : []
    downloadList.value = downloadArray

    const showDownloadArea = ref(false)

    const removeFile = async (file) => {
        let index = files.value.indexOf(file)
        files.value.splice(index, 1)
        filenames.value.splice(index, 1)
        let fileArray = JSON.parse(await localforage.getItem('downloadList'))

        let sameFiles = document.querySelectorAll('input[data-id="' + file + '"]')
            
        sameFiles.forEach(checkbox => {
            checkbox.checked = false
        });

        fileArray = fileArray.filter(arrFile => arrFile.fileID !== file)
        await localforage.setItem('downloadList', JSON.stringify(fileArray))

        fileCount.value = filenames.value.length
    }

    const removeAll = async () => {
        files.value = []
        filenames.value = []

        const downloadableContent = document.getElementsByClassName('download-checkbox')

        for (let i = 0; i < downloadableContent.length; i++) {
            downloadableContent[i].checked = false
        }

        await localforage.removeItem('downloadList')

        fileCount.value = 0
    }

    const downloadFiles = async() => {
        const files = JSON.parse(await localforage.getItem('downloadList'))
        const fileIDList = files.map(value => value.fileID)
        console.log(fileIDList)

        // fetch('', {
        //     method: 'POST',
        //     headers: {
        //         "Content-type": "application/json; charset=UTF-8"
        //     },
        //     body: JSON.stringify(fileIDList)
        // })
    }
</script>

<style lang="scss">
    .file-amount-container {
        color: var(--color-white);
        background-color: var(--color-black);
        aspect-ratio: 1/1;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 50%;
        position: absolute;
        top: -5px;
        right: -10px;

        .file-amount {
            font-size: 14px;
            line-height: 1.7;
        }
    }
</style>