<template>
    <OffcanvasTransition>
        <aside 
            class="sidenav__container"
            :class="opened ? 'opened': ''"
        >
            <div 
                class="sidenav__trigger btn btn-secondary btn-circle-arrow"
                @click="toggleMenu()"
            ></div>
    
            <div class="page__title">
                <HeaderTitle @toggleLoginModal="(value) => openLoginModal(value)" />
            </div>
            
            <div
                v-if="props.mobileView"
                class="page__search sidenav__search"
            >
                <PageSearch />
            </div>
            
            <div class="page__navigation side__navigation">
                <MenuNavigation :menuType="'sideMenu'" />
            </div>
            
            <!-- <menu v-if="route.path !== '/'" class="sub__navigation"> -->
            <menu v-if="currentPageID" class="sub__navigation">
                <div
                    v-if="categories"
                    v-for="(category, index) in categories"
                    :key="index"
                    class="sub__menu"
                >
                    <CategoryTransition>
                        <h3 v-if="category.title !== 'Lorem Ipsum'">{{ category.title }}</h3>
                    </CategoryTransition>
                    
                    <MenuTransition>
                        <li
                            v-if="pageContent.widgets"
                            v-for="(listItem, index) in getMenuList(category.catID)"
                            :key="index"
                        >
                            <NuxtLink
                                :to="listItem.path"
                                class="sub__menu-link"
                                :data-id="listItem.processID"
                                @click.native="generalStore.setSubPage(listItem.path); setContent()"
                            >
                                {{ listItem.title }}
                            </NuxtLink>
                        </li>
                    </MenuTransition>
                </div>
            </menu>
        </aside>
    </OffcanvasTransition>
</template>

<script setup>
    import { useRouter } from 'vue-router'
    import { useMainContentStore } from '/stores/mainContent'
    import { useGeneralStore } from '/stores/general'
    import { useMenuStore } from '/stores/menues'
    
    const props = defineProps({
        page: String,
        mobileView: Boolean
    })

    const emit = defineEmits([
        'toggleLoginModal'
    ])

    const openLoginModal = (value) => {
        emit('toggleLoginModal', value)
    }

    const router = useRouter()
    const path = ref(router.currentRoute.value.path)
    const route = useRoute()

    watch(route, () => {
        path.value = router.currentRoute.value.path
    })

    const contentStore = useMainContentStore()
    const generalStore = useGeneralStore()
    const menuStore = useMenuStore()
    const { pageContent } = storeToRefs(contentStore)
    const { currentSubPage, currentPageID, parentPageID } = storeToRefs(generalStore)
    const { mainMenu } = storeToRefs(menuStore)

    const getCategories = () => {
        let cats = []
        let currentPageMenu = mainMenu.value.filter(navpoint => navpoint.route === route.params.secondlevel)

        if (currentPageMenu.length === 1 && currentPageMenu[0].submenu.length > 0) {
            currentPageMenu[0].submenu.forEach(subNavPoint => {
                subNavPoint.categories.forEach((cat) => {
                    cats.push({
                        'title': cat.name,
                        'catID': cat.processID
                    })
                });
            });
        }

        const removeDupes = (arr, map = new Map()) => {
            arr.forEach(o => map.set(o.catID, o));

            return [...map.values()];
        }

        return removeDupes(cats)
    }

    const getMenuList = (filter) => {
        let menuList = []

        if (mainMenu.value.length > 0) {
            mainMenu.value.forEach(mainNavPoint => {
                const parentID = parentPageID.value
                const parentObject = mainMenu.value.filter((menuPoint) => menuPoint.processID === parentID)

                if (parentObject.length > 0) {
                    parentObject[0].submenu.forEach(subNavPoint => {
                        subNavPoint.categories.forEach(cat => {
                            if (cat.processID === filter) {
                                menuList.push({
                                    'title': subNavPoint.title,
                                    'path': `/${subNavPoint.route}`,
                                    'processID': subNavPoint.processID
                                })
                            }
                        });
                    });
                }
            });
        }

        const ids = menuList.map(({ processID }) => processID)
        menuList = menuList.filter(({ processID }, index) => !ids.includes(processID, index + 1))

        return menuList
    }

    const setContent = () => {
        if(currentSubPage.value.length > 0) {
            contentStore.createSubPageContent(pageContent.value.widgets.filter(widget => widget.meta.sectionId.includes(currentSubPage.value) === true))
        } else {
            contentStore.createSubPageContent(pageContent.value.widgets.filter(widget => widget.meta.sectionId.includes(route.params.subpages[1]) === true))
        }
    }
    
    // get all categories
    let categories = ref([])

    watch(pageContent, () => {
        categories.value = []

        if (Object.keys(pageContent.value).length > 0) {
            categories.value = getCategories()
        }
    })

    // open/close menu
    let opened = ref(false)
    
    const toggleMenu = () => {
        const body = document.querySelector('body')
        const toggler = document.querySelector('.sidenav__trigger')

        if (!opened.value) {
            toggler.classList.add('active')
        } else {
            toggler.classList.remove('active')
        }

        opened.value = !opened.value
    }
</script>